import 'bootstrap/dist/css/bootstrap.css';
import './footer.css'
import { useEffect } from 'react';
import $ from 'jquery'
import bedab from '../assets/Bedab-Logo-Blue.png'
import tijary from '../assets/tijarylog.jpeg'
import { WhatsApp } from '@mui/icons-material';
import whats from '../assets/733585.png'
import EmailIcon from '@mui/icons-material/Email';
import { useState } from 'react';
import Swal from "sweetalert2";
import { AiFillPlayCircle } from "react-icons/ai";

import { FacebookShareButton } from 'react-share';
import { FacebookMessengerShareButton } from 'react-share';

import { TwitterShareButton } from 'react-share';
import { WhatsappShareButton } from 'react-share';
import { FaShareNodes } from "react-icons/fa";
import { HiShare } from "react-icons/hi";
import { AiFillCopy } from "react-icons/ai";
import { useRef } from 'react';
import { SocialIcon } from 'react-social-icons';
import myVideo from './video.mp4'

import {
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";
function Footer(props){
  const safeDocument = typeof document !== 'undefined' ? document : {};

  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;
  const { body } = safeDocument;
  const bodyScrollLock = require('body-scroll-lock');
    const openModal = () => {
        $('.modal-yasser').removeClass("d-none");
        $('.modal-yasser').addClass("d-block");
        $('body').addClass("disable-scroll");
      };
      
      const closeModal = () => {
        $('.modal-yasser').removeClass("d-block");
        $('.modal-yasser').addClass("d-none");
        $('body').removeClass("disable-scroll");
      };
      const[showagain,setshowagain]=useState(false)

      const videoload = event =>{
        Swal.fire({
          // icon: 'info',
          html:
            // '<iframe  class="iframwidth" height="300" src="https://www.youtube.com/embed/5KtYqJwpgRg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            ' <div> <video width="500" height="315" controls autoplay> <source src='+myVideo+' type="video/mp4"  />Your browser does not support the video tag.</video> </div>' 
            +'<label class="fontmaaray">عدم اظهار الفيديو مره اخري</label>'
             +'<input type="checkbox" id="cheackinvideo" >',
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText:
            'موافق',
          confirmButtonAriaLabel: 'Thumbs up, great!',
      
        }).then((result) => {
          // console.log("cheackinvideo",document.getElementById("cheackinvideo").checked)
      const x=document.getElementById("cheackinvideo").checked
      const Elecheck=document.getElementById("cheackinvideo")
          if (result.isConfirmed && x === true) {
            setshowagain(true)
            localStorage.setItem('showagain', true)
            Elecheck.setAttribute("checked","")
            
      
          }
         
        else{
          localStorage.setItem('showagain', false)
      
      
        }
        
        })
      
        var cheackinvideo = document.getElementById("cheackinvideo");
        var showagain = localStorage.getItem('showagain')
        console.log("showagain",showagain)
        console.log("Check",cheackinvideo);
        if(showagain == "true")
           cheackinvideo.setAttribute("checked",showagain);
        else
           cheackinvideo.removeAttribute("checked");
           localStorage.setItem('showagain', false)
      
      }

    const openModalofshare = () => {
      $('.modalshare').removeClass("d-none");
      $('.modalshare').addClass("d-block");
      // $('body').addClass("disable-scroll");
      if (!body || !body.style || scrollBlocked.current) return;

      const scrollBarWidth = window.innerWidth - html.clientWidth;
      const bodyPaddingRight =
        parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;
  
      /**
       * 1. Fixes a bug in iOS and desktop Safari whereby setting
       *    `overflow: hidden` on the html/body does not prevent scrolling.
       * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
       *    scroll if an `overflow-x` style is also applied to the body.
       */
      html.style.position = 'relative'; /* [1] */
      html.style.overflow = 'hidden'; /* [2] */
      body.style.position = 'relative'; /* [1] */
      body.style.overflow = 'hidden'; /* [2] */
      // body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;
  
      scrollBlocked.current = true;
      // $(":button").attr('disabled', true);//button remains disabled
      $("#itemop").css("pointer-events", "none");
    };
    
    const closeModalofshare = () => {
      $('.modalshare').removeClass("d-block");
      $('.modalshare').addClass("d-none");
      // $('body').removeClass("disable-scroll");
      if (!body || !body.style || !scrollBlocked.current) return;
      html.style.position = '';
      html.style.overflow = '';
      body.style.position = '';
      body.style.overflow = '';
      body.style.paddingRight = '';
      scrollBlocked.current = false;
    };
    const [url,seturl]=useState(window.location.href)

    return(
        <div className='container-fluid mt-4'>
            <div className="container-fluid">
       {props.footer.items.length > 0 ?
         <div className='row' style={{'backgroundColor':props.footer.color}}>
       <div className='col-12 fs-1 '>
         <h3 className='mt-1 fontmaaray' style={{'color':props.footer.fontColor}}>{props.footer.title}</h3>
        </div>
        <div className="row justify-content-center mt-2">
       {props.footer.items.map((footer, index) => (
        <div className="col-2 col-lg-2 col-sm-4"style={{'margin-bottom':'15px'}}>
        <a href={footer.url} className='w-100' target="_blank">
         <img src={footer.image} style={{width:'30px'}} ></img>  
         </a>    
         </div>
       ))}
       </div>
        </div> 
        : "" }
 <div className='col-11' >
 <div className='row' >
<div className='col-lg-2 col-5' style={{marginLeft:"4%",border:"1px solid lightgray",borderRadius:"6px",color:"white",background:"red"}} onClick={videoload}>
<div className='d-flex' style={{justifyContent:"center",marginTop:"8%"}}>
<div className='col-lg-9 col-5'>
<p className='fontmaaray' style={{fontSize:"19px"}}>شرح </p>
</div>
<div className='col-lg-3 col-3 p-0'>
<AiFillPlayCircle  style={{fontSize:"35px",color:"white"}}></AiFillPlayCircle>
</div>

</div>
</div>
<div className='col-lg-7 col-1'>

</div>
<div className='col-lg-2 col-5' style={{border:"1px solid lightgray",color:"white",borderRadius:"6px",background:"#0d6efd"}} onClick={openModalofshare}> 
<div className='row'>

<div className='col-6'>
<p className='fontmaaray' style={{marginTop:"23%",textAlign:"right",fontSize:"19px"}}>
  مشاركه
</p>
</div>
<div className='col-6'>
<HiShare className='' style={{marginTop:"21%",fontSize:"30px"}}> </HiShare>

</div>

</div>
</div>
</div>
 </div>



    <div className="footer-- text-center  d-flex justify-content-center align-items-center ">
          <div className='pt-1'>
          <img src={tijary} className='mx-1' style={{width:"80px"}} alt=""  onClick={openModal} />
          </div>
         

        </div>
      </div>

      <div className="modal-yasser  d-none d-flex justify-content-center  align-items-center" >
        <div className="modal-dialog  modal-dialog-centered" >
          <div className="modal-content pb-3 ">
            <div className="modal-header">
              <div className="d-flex justify-content-center" style={{ width: "95%" }}>
                <h1 className="modal-title fs-2" style={{ marginTop: "10px", fontSize: "18px", color: "rgb(25, 135, 84)", fontWeight: "bold", textShadow: "rgb(191, 191, 191) 2px 4px 4px" }}>
                tijary عن 
                </h1>
              </div>
              <div className="">
                <button type="button" className="btn-close btn " onClick={closeModal}></button>
              </div>
            </div>
            <div className="modal-body">
             <div className="d-flex flex-column justify-contnent-center align-items-center">
             <p className='text-center fontmaaray'>نظام متكامل للأداره الشاملة لمحلات البيع و متطلبات عملائهم من خلال مجموعه من التطبيقات وتكنولوجيا اداره المعلومات</p>
         <p className='text-center fontmaaray'>يقوم النظام باداره محلات البيع بما يتطلب من زياده الأرباح والتوفير وتقليص الخسائر </p>
         <p className='text-center fontmaaray mb-0'>يتركز علي ارسال التفوق الاداري و انجاح العمليه التجاريه سواء اونلاين او اوف لاين للانترنت المحل</p>
             </div>
            </div>
            <div className="modal-footer d-flex justify-content-around border-0">
            <a className="col-2  p-2 fs-2" href="https://wa.me/201118118343" target="_blank">
                <i style={{ color: '#27b459'  , fontSize:"1.2rem"}}>
                 {/* <img className='w-50' src={whats}/> */}
                 <WhatsApp color="success"
                 sx={{ fontSize: 40 }}></WhatsApp>
                     </i>
              </a>
            
              <a className="text-decoration-none" href="mailto:Support@tijary.store">
                <button type="button" className="btn p-2 px-3 " style={{ backgroundColor: "#F56902", color: "#fff" }}>
                <EmailIcon className='mx-1'></EmailIcon>
                 ارسل استفسارك 
                </button>
              </a>
            
            </div>
            <div className="d-flex flex-column mt-2 ">
          <p className="mb-0  text-muted mx-1" style={{ fontSize: "0.6rem" }}>
            Developed by
          </p>
          
          <a className="text-decoration-none mb-2 " href="http://www.bedab.com/contact-arabic.html">
              <img src={bedab} className=" " style={{ width: "70px" }} alt="" />
            </a>
          
          </div>
          </div>
        </div>
        </div>

        <div className="modalshare  d-none d-flex justify-content-center  align-items-center" >
        <div className="modal-dialog  modal-dialog-centered" >
          <div className="modal-content pb-3 ">
            <div className="modal-header">
            <div className="">
                <button type="button" className="btn-close btn btn-danger" onClick={closeModalofshare}></button>
              </div>
              <div className="d-flex justify-content-center" style={{ width: "95%" }}>
                <h1 className="modal-title fs-2 fontmaaray" style={{ marginTop: "10px", fontSize: "18px", color: "rgb(25, 135, 84)", fontWeight: "bold", textShadow: "rgb(191, 191, 191) 2px 4px 4px" }}>
               شارك 
                </h1>
              </div>
            
            </div>
            <div className="modal-body">
             <div className="row justify-content-center">
         <div className='col-4'>
         <FacebookShareButton
        url={`${url}`}
        quote={'Dummy text!'}
        hashtag="مجله زاهر"
      >
        <FacebookIcon size={50} round />
      </FacebookShareButton>
         </div>
<div className='col-4'>
<TwitterShareButton
  url={`${url}`}
  quote={'Dummy text!'}
  hashtag="مجله زاهر"
>
<SocialIcon url="https://x.com" />
  {/* <TwitterIcon size={50} round /> */}
</TwitterShareButton>
</div>
<div className='col-4'>
<WhatsappShareButton
  url={`${url}`}
  quote={'Dummy text!'}
  hashtag="مجله زاهر"

>
<WhatsappIcon size={50} round />

</WhatsappShareButton>
</div>

{/* <div className='col-2'>
<FacebookMessengerShareButton
  url={'https://magala.zaherdairy.com/'}
  quote={'Dummy text!'}
  hashtag="مجله فبصل"

>
<FacebookMessengerIcon size={32} round />

</FacebookMessengerShareButton>

</div> */}
             </div>
             <div className='row mt-3' style={{justifyContent:"center"}}>
<div className='col-11'>
<div className='row'>


<div className='col-12'>

<button className='btn btn-success w-100 '  onClick={() =>  navigator.clipboard.writeText(`${url}`)}


>
نسخ الرابط <AiFillCopy></AiFillCopy>
</button>
</div>
</div>
</div>
             </div>
            </div>
      
       
          </div>
        </div>
        </div>
        </div>
    )
}
export default Footer