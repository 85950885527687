import './App.css';
import Header from './nav/header';
import BasicExample from './container/container';
import UncontrolledExample from './carsoul/slider';
import {useState} from 'react';
import RecordView from './recorder/recorder';
import Formuser from './form-user/form';
import React from 'react';
import uploadFileToBlob, { isStorageConfigured } from './recorder/azureBlob';
import Footer from './footer/footer';
import { useEffect } from "react";
import axios from "axios";
import Nomagazine from './nomagazine/nomagazine';
import { useRef } from "react";
import Swal from "sweetalert2";
import ReactGA from "react-ga4";

import myVideo from './video.mp4'



function App() {
  const[regionValefun,setregionValefun]=useState()

  const[Recfile,setRecfile]=useState()
  const[CommentNomagazine,setCommentNomagazine]=useState()

const TRACKING_ID="G-DJ31SE1F27"
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({ hitType: "pageview", page: "https://tijarygoodsdealmagazine.azurewebsites.net/", title: "goodsdeal" });


    const[showagain,setshowagain]=useState(true)
 
//  const TRACKING_ID = "UA-12341234-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function videoload(){
  Swal.fire({
   
    // icon: 'info',
    html:
      '<video width="500" height="315" controls autoplay> <source src='+myVideo+' type="video/mp4"  />Your browser does not support the video tag.</video>'
      +'<label class="fontmaaray">عدم اظهار الفيديو مره اخري</label>'

      +'<input type="checkbox" id="cheackinvideo">',
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText:
      'موافق',


  }).then((result) => {
    if (result.isConfirmed && result.value === true) {
      const x=document.getElementById("cheackinvideo").checked
      setshowagain(true)
      localStorage.setItem('showagain', true)
      document.getElementById("cheackinvideo").checked=true


    }
  else{
    localStorage.setItem('showagain', false)


  }
})

}
  const merchantMagazineId = 23

  useEffect(()  => {

    if(localStorage.getItem('showagain')== "false"){
      videoload()
    }  
 
    
    fetchIdmagazine(merchantMagazineId);
   },[])
   
   /**
    * Fetches the ID of a magazine for a given merchant ID.
    *
    * @param {number} merchantId - The ID of the merchant.
    * @return {Promise} A Promise that resolves to the ID of the magazine.
    */
   const fetchIdmagazine = (merchantId) => {
    return axios.get("https://tijarymagazineapis.azurewebsites.net/GetLastMagazineIDwithMagazineMerhanctID/"+merchantId)
          .then((response) => {
           fetchHeader(response.data.magazineID);
       
            });
  }
  const [header, setHeader] = useState();
  const [comment, setcomment] = useState();
  
  const fetchHeader = (magazineID) => {
    
    return axios.get('https://tijarymagazineapis.azurewebsites.net/api/MagazineHead/'+magazineID)
          .then((response) => {
            setHeader(response.data);
          })
         
  }

////////////////////to upload file//////////////////////
  const [fileuplod,setFileuplod]=useState()
  const[mediaBlobUrl,setmediaBlobUrl]=useState('')
      const [blobList, setBlobList] = useState([]);
const [orderlist, setorderlist] = React.useState([]);   
// current file to upload into container
  const [fileSelected, setFileSelected] = useState(null);
  // UI/form management
  const [uploading, setUploading] = useState(false);
  const [inputKey, setInputKey] = useState(Math.random().toString(36).split());
  const onFileUpload = async (pathName) => {
    // prepare UI
    setUploading(true);
    // *** UPLOAD TO AZURE STORAGE ***
    const blobsInContainer = await uploadFileToBlob(pathName);
    // prepare UI for results
    setBlobList(blobsInContainer);
    // reset state/form
    setFileSelected(pathName);
    setUploading(false);
    setInputKey(Math.random().toString(36));
  }
  /////////////////////////convert blob media ////////////////
  const  converttofile =  async(e) => {
    const audioBlobss = await fetch(mediaBlobUrl).then((r) => r.blob());
    const audioFile = new File([audioBlobss], 'voice.wav', { type: 'audio/wav'});

    onFileUpload(audioFile)
  }
  const divRef = useRef();
  function handleScroll(e) {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0, 
      behavior:'smooth'
    });
    const x=document.getElementById('scrollbutton')
   if(document.body.offsetHeight){
    x.style.display='none'
   }
  }
  const[elementprint,setelementprint]=useState(false)
  const[saleId,setsaleId]=useState()
  const[saleIdman,setsaleIdman]=useState()
  const[delvVal,setdelvVal]=useState()
  return (
    <div className="App"  >
       {header  ?  <div style={{"background":header.backGround}}>
    {elementprint==true?  "" : <div id='scrolling-map'>
    
    <header className="App-header"  id='Headop'>
        <Header headerObj={header}> </Header>
      </header>
    <UncontrolledExample id="sliderOp" headerObj={header}></UncontrolledExample>
<div className='mt-2'>
</div>
    <div className='mt-4'></div>
    <BasicExample OrderItemLst = {orderlist}
    SetOrderItemLst = {setorderlist}
    headerObj={header}
    setsaleIdP={setsaleId}
    saleIdVP={saleId}
    saleIdmanVP={saleIdman}
    setsaleIdmanP={setsaleIdman}
    delvVal={delvVal}
></BasicExample>
<div className='mt-4'></div>

<RecordView  id="recordOp"
OnmediaBlobUrlChange={setmediaBlobUrl}
handleupload={onFileUpload}
flieup={setFileuplod}
up={converttofile}
setcomment = {setcomment}
comment = {comment}
setRecfile={setRecfile}
setCommentNomagazine={setCommentNomagazine}
></RecordView>
<Formuser 
id="formOp"
OrderItemLst = {orderlist}
flieup = {fileuplod}
comment = {comment}
headerObj={header}
merchantMagazineId={merchantMagazineId}
handleprint={setelementprint}
saleIdVP={saleId}
saleIdmanVP={saleIdman}
setregionValefun={setregionValefun}
regionValefun={regionValefun}
setdelvVal={setdelvVal}
delvVal={delvVal}
></Formuser>
<div id="footer">
<Footer footer={header.footer}/>
</div>

    
    
    </div>}
   


  </div> :   <Nomagazine 
  flieup = {fileuplod}
  comment = {comment}
  merchantMagazineId={merchantMagazineId}
  Recfile={Recfile}
  setRecfile={setRecfile}
  setCommentNomagazine={setCommentNomagazine}
  CommentNomagazine={CommentNomagazine}

  ></Nomagazine>}
    </div>
    
  );
}


export default App;
