import 'bootstrap/dist/css/bootstrap.css';
import React, { useState } from 'react';
import './container.css';
import { BsCart2 } from "react-icons/bs";
import { useEffect } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import EditIcon from '@mui/icons-material/Edit';
import $ from 'jquery';
import Swal from "sweetalert2";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { IoBackspaceSharp } from "react-icons/io5";
import { RxDotFilled } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useRef } from 'react';
import moment from 'moment';
import 'moment/locale/ar';
import html2pdf from 'html2pdf.js';

function BasicExample(props) {
 const FnumDecimal=parseFloat("0.125").toFixed(2)
 const SnumDecimal=parseFloat("0.25").toFixed(2)
 const THnumDecimal=parseFloat("0.33").toFixed(2)
 const FOnumDecimal=parseFloat("0.5").toFixed(2)
  const safeDocument = typeof document !== 'undefined' ? document : {};

  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;
  const { body } = safeDocument;
  const bodyScrollLock = require('body-scroll-lock');
  const disableBodyScroll = bodyScrollLock.disableBodyScroll;
  const enableBodyScroll = bodyScrollLock.enableBodyScroll;
  ///////////data of order to send to form ///////
  const [scroll, setscroll] = useState(0)
  const [popupprice, setpopupprice] = useState(false)
  const [showarray, setshowarray] = useState(false)
  const [array, setarray] = useState([])
  const [btnval, setbtnvalue] = useState()
  const [priceinpopupprice, setpriceinpopupprice] = useState()
  const [Nameinpopupprice, setNameinpopupprice] = useState()
  const [sectionid, setsectionid] = useState()
  const [eqvitem, seteqvitem] = useState()
  const [eqvitemid, seteqvitemid] = useState()
  const [dropdowneqv, setdropdowneqv] = useState(false)
  const [iddrodwn, setiddrodwn] = useState()
  const [eqvid, seteqvid] = useState()
  const [eqvname, seteqvname] = useState()
  const [itemObj, setitemObj] = useState()
  const [LogoImageBase64, setLogoImageBase64] = useState("")
  // const [popupofaddone, setpopupofaddone] = useState(false)
  const [Section, setSection] = useState([]);

  useEffect(() => {
    setSection(props.headerObj.sections);
    getBase64ImageFromUrl(props.headerObj.logo)
    .then(result => setLogoImageBase64(result))
    .catch(err => console.error(err));
  }, []);

  /**
   * Determines the operating system based on the user agent.
   *
   * @return {string} The name of the operating system (iOS, Android, Windows, Mac, Linux, or Unknown).
   */
  const getOperatingSystem = () => {
    const userAgent = window.navigator.userAgent;
    const platforms = {
      ios: /iPad|iPhone|iPod/.test(userAgent),
      android: /Android/.test(userAgent),
      windows: /Win/.test(userAgent),
      mac: /Mac/.test(userAgent),
      linux: /Linux/.test(userAgent),
    };
  
    // Determine the operating system
    if (platforms.ios) {
      return 'iOS';
    } else if (platforms.android) {
      return 'Android';
    } else if (platforms.windows) {
      return 'Windows';
    } else if (platforms.mac) {
      return 'Mac';
    } else if (platforms.linux) {
      return 'Linux';
    } else {
      return 'Unknown';
    }
  };

  const objtotal = {
    'totalqut': 0,
    'totalpricedel': 0,
    'totaldiscount': 0,
    'totalprice': 0,
    'Nettotal': 0
  }


  const ChangeQty2 = (Item) => {
    // Update state with incremented value
    if (array.length == 0) {
      // console.log(array)
      if(Item.qty==0){
        // Swal.fire("من فضلك ادخل الكميه")
        setpopupprice(false)

      }
       else{
        orderdataInpop(Item)
        setpopupprice(false)
       }
    }
   else if (array.length == 1) {
      Item.qty = array[0].toString()
      if(Item.itemMaximumOrderQty==0){
        orderdataInpop(Item);
      }
      else{
        if(parseInt(Item.qty)>Item.itemMaximumOrderQty){
          Swal.fire("لقد تعديت الحد الاقصي")
          Item.qty=Item.itemMaximumOrderQty
          orderdataInpop(Item);
        }
        else{
          orderdataInpop(Item);
        }
      }
   
  
     }
    else if (array.length == 2) {
      Item.qty = array[0].toString() + array[1].toString();
      if(Item.itemMaximumOrderQty==0){
        orderdataInpop(Item);
      }
     else{
        if(parseInt(Item.qty)>Item.itemMaximumOrderQty){
          Swal.fire("لقد تعديت الحد الاقصي")
          Item.qty=Item.itemMaximumOrderQty
          orderdataInpop(Item);
        }
        else{
          if(parseInt(Item.itemQty)>Item.itemMaximumOrderQty){
            Swal.fire("لقد تعديت الحد الاقصي")
            Item.qty=Item.itemMaximumOrderQty
            orderdataInpop(Item);
          }
          else{
            orderdataInpop(Item);
          }
        }
  
      
    }
  }
    else if (array.length == 3) {
      Item.qty = array[0].toString() + array[1].toString() + array[2].toString();
      if(Item.itemMaximumOrderQty==0){
        orderdataInpop(Item);
      }
      else{
        if(parseInt(Item.qty)>Item.itemMaximumOrderQty){
          Swal.fire("لقد تعديت الحد الاقصي")
          Item.qty=Item.itemMaximumOrderQty
          orderdataInpop(Item);
        }
        else{
          if(parseInt(Item.qty)>Item.itemMaximumOrderQty){
            Swal.fire("لقد تعديت الحد الاقصي")
            Item.qty=Item.itemMaximumOrderQty
            orderdataInpop(Item);
          }
          else{
            orderdataInpop(Item);
          }
        }
  
      
    }
  
 
    }
    else if (array.length == 4 ) {
      Item.qty = array[0].toString() + array[1].toString() + array[2].toString() + array[3].toString();
      if(Item.itemMaximumOrderQty==0){
        orderdataInpop(Item);
      }
      else{
        if(parseInt(Item.qty)>Item.itemMaximumOrderQty){
          Swal.fire("لقد تعديت الحد الاقصي")
          Item.qty=Item.itemMaximumOrderQty
          orderdataInpop(Item);
        }
        else{
          if(parseInt(Item.qty)>Item.itemMaximumOrderQty){
            Swal.fire("لقد تعديت الحد الاقصي")
            Item.qty=Item.itemMaximumOrderQty
            orderdataInpop(Item);
          }
          else{
            orderdataInpop(Item);
          }
        }
  
      
    }
   

    
    }
    else if (array.length > 4) {
      if(Item.qty>Item.itemMaximumOrderQty  && Item.itemMaximumOrderQty > 0){
        Swal.fire("لقد تعديت الحد الاقصي")
      }
      else{
        orderdataInpop(Item);
  
      }
    }
    // if(parseInt(Item.qty)>Item.itemMaximumOrderQty){
    //   Swal.fire("لقد تعديت الحد الاقصي")
    //   Item.qty=Item.itemMaximumOrderQty
    //   orderdataInpop(Item)
    // }
    
    const updatedSection = [...Section]; // Create a copy of the Section array

    updatedSection.forEach((section) => {
      section.items.forEach((item) => {
        if (item.itemID === Item.itemID) {
          // console.log("item.itemID" ,item.itemID)
          // console.log("Item.itemID" ,Item.itemID)
        
            item.itemQty = Item.qty;


          
          // console.log("item.itemQty" ,item.itemQty)
           // Update the item's itemQty with Item.qty
        }
      });
    });
    
    // console.log("updatedSection", updatedSection);
    setSection(updatedSection);
    
    
  // console.log(updatedItems)
  }



  function showarrayleng(item) {
    
    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false; 

  $("#itemop").css("pointer-events", "visible");

  $("#itemop").css("opacity", "1");
  $("#Headop").css("opacity", "1");
  $("#sliderOp").css("opacity", "1");
  $("#recordOp").css("opacity", "1");
  $("#formOp").css("opacity", "1");
  $("#footer").css("opacity", "1");
    ChangeQty2(item)
    setpopupprice(false)
    setarray([])
    setshowarray(false)
    // console.log( "itemzzzz",item)
  }
  function setbtnval1(value) {
  
    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)
      setarray(current => [...current, value]);
      setshowarray(true)
    }

  }
  // $( "#itemop" ).scrollTop();


  function closepricepopup() {


    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false;

    // $("#Allcontainer").css("background", "white");
    // const $body = document.querySelector('body');
    // $body.style.removeProperty('overflow');
    // $body.style.position = 'relative';
    // // $body.style.removeProperty('position');
    // $body.style.removeProperty('width');
    // // window.scrollTo({top: scroll});
    setpopupprice(false)
    setarray([])
    setshowarray(false)
    
  $("#itemop").css("pointer-events", "visible");
  $("#itemop").css("opacity", "1");
  $("#Headop").css("opacity", "1");
  $("#sliderOp").css("opacity", "1");
  $("#recordOp").css("opacity", "1");
  $("#formOp").css("opacity", "1");
  $("#footer").css("opacity", "1");
  // $body.style.top = `${scroll}px`;

  }

  const zeroitem = (Item) => {
    // Update state with incremented value

    setpopupprice(false)
    Item.itemQty = 0;
    orderdata(Item);
  }

  const [iteminbtn, setiteminbtn] = useState()

  const showpopuppriceitem=(order)=>{
   const objorder = {
        'itemID': order.itemID,
        'qty': order.itemQty,
        'itemName': order.itemName,
        'pricedel': order.itemSalesPrice,
        'price': order.itemDiscountedPrice,
        'discount': order.itemSalesPrice - order.itemDiscountedPrice,
        "total": order.itemDiscountedPrice,
        "netprice": order.itemDiscountedPrice,
        'deliveryOrder':props.headerObj.defaultDeliveryPrice,
        "itemMaximumOrderQty":order.itemMaximumOrderQty,
        "itemEqvUnts":order.itemEqvUnts,
        "defaultUnt":order.defaultUnt
  
      }
      showpopupprice(objorder);
 
  
      }

  
 
  const showpopupprice = (order) => {
    setpopupprice(true)
    setpriceinpopupprice(order.netprice)
    // setiteminbtn(order)
    // setdropdowneqv(false)
    setitemObj(order)
    // var scrollpos = window.scrollY;
    // setscroll(scrollpos)
    
    // $("#Allcontainer").css("overflow", "hidden");
    // $("#root").css("overflow", "hidden");
    // $("#root").css("position", "relative");
    // $("#popup").css("position", "absolute");

    // $body.style.top = `-${scrollpos}px`;
    // $body.style.width = '100%';
    if (!body || !body.style || scrollBlocked.current) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;

    /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    html.style.position = 'relative'; /* [1] */
    html.style.overflow = 'hidden'; /* [2] */
    body.style.position = 'relative'; /* [1] */
    body.style.overflow = 'hidden'; /* [2] */
    // body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
    // $(":button").attr('disabled', true);//button remains disabled
    $("#itemop").css("pointer-events", "none");
    $("#itemop").css("opacity", "0.5");
    $("#Headop").css("opacity", "0.5");
    $("#sliderOp").css("opacity", "0.5");
    $("#recordOp").css("opacity", "0.5");
    $("#formOp").css("opacity", "0.5");
    $("#footer").css("opacity", "0.5");
    // $("#Allcontainer").css("background", "#919294");
    // window.scrollTo({top: scrollpos});
    if(order.defaultUnt.isDecimal==true){
      $("#popup").css("height", "535px");
    }
    else{
      $("#popup").css("height", "515px");
    }
    // document.body.style.top = `-${window.scrollY}px`
    // document.body.style.position = 'fixed'
  //   $('body').css({
  //     overflow: 'hidden',
  //     height: '100%'
  // });
    //   setNameinpopupprice(itemObj.itemName)
    // console.log("order", order)

  }

  // console.log("iteminbtn", iteminbtn)
  // console.log("itemobj", itemObj)
  const [total, settotal] = React.useState(objtotal);
  //////////////////////function to increase button and update the value of quantity /////////////////
  const ChangeQty = (Item, Sign) => {
    // Update state with incremented value
    if (Sign == '+')
      var quantity = parseFloat(Item.itemQty) + 1;
    else if (Sign == '-')
      var quantity = parseFloat(Item.itemQty) - 1;
    else if (Sign == '0')
      var quantity = 0;

    if (quantity > Item.itemMaximumOrderQty && Item.itemMaximumOrderQty > 0) {
      // +Item.itemMaximumOrderQty
      Swal.fire("  لقد وصلت الي اقصي كميه لهذا الصنف" )
      quantity = Item.itemMaximumOrderQty;
    }
    else if (quantity < 0) {
      Swal.fire("تم حذف الصنف من قائمتك يمكن اعاده اختياره ")
      quantity = 0;
    }

    Item.itemQty = quantity.toFixed(2);
    orderdata(Item);
    // setitemObj(Item)
    // setiteminbtn(Item)
  }

  ////////////////order list /////////////////
  /////////use order for orderdata////
// console.log("regionvalue",props.regionvalue)

// console.log("OrderItemLst",props.headerObj.defaultDeliveryPrice)
const orderdata = (order) => {
    ///define object of order and set the value 
    
    const orderlist = props.OrderItemLst
    if(props.regionvalue==undefined){
      const objorder = {
        'itemID': order.itemID,
        'qty': order.itemQty,
        'itemName': order.itemName,
        'pricedel': order.itemSalesPrice * order.itemQty,
        'price': order.itemDiscountedPrice * order.itemQty,
        'discount': order.itemSalesPrice - order.itemDiscountedPrice,
        "total": order.itemDiscountedPrice,
        "netprice": order.itemDiscountedPrice,
        'deliveryOrder':props.headerObj.defaultDeliveryPrice,
        "itemMaximumOrderQty":order.itemMaximumOrderQty,
        "itemEqvUnts":order.itemEqvUnts,
        "defaultUnt":order.defaultUnt
  
      }
      let totalpricedel = 0;
      let totalqty = 0;
      let totalprice = 0;
      let totaldiscount = 0;
      let Nettotal = 0;
      //condition of quantaity
      if (order.itemQty != 0) {
        //set the order in temporary array and slice orderlist 
        let index = orderlist.findIndex(orderlist => orderlist.itemID === order.itemID);
        if (index !== -1) {
          let temporaryarray = orderlist.slice();
          //asign temporary arry of index to orderdata 
          temporaryarray[index]["qty"] = order.itemQty;
          temporaryarray[index]["pricedel"] = order.itemSalesPrice ;
          temporaryarray[index]["total"] = order.itemQty * order.itemDiscountedPrice;
          temporaryarray[index]["price"] = order.itemDiscountedPrice ;
          temporaryarray[index]["netprice"] = order.itemDiscountedPrice;
          temporaryarray[index]["itemEqvUnts"] =order.itemEqvUnts;

          temporaryarray[index]["itemMaximumOrderQty"] = order.itemMaximumOrderQty;
  
          //loop for temporary array to show the data 
          temporaryarray.forEach((ele) => {
            totalqty = totalqty + Number(ele.qty);
            totalpricedel = totalpricedel + ele.pricedel;
            totaldiscount = totaldiscount + (ele.discount * ele.qty);
            totalprice = totalprice + ele.price;
            Nettotal = Nettotal + Number(ele.total);
          })
          // if quantaity !=-1 
        } else {
          const NeworderLst = [...orderlist, objorder];
          props.SetOrderItemLst(NeworderLst)
          //set te data of neworderlist and loop to that 
          NeworderLst.forEach((ele) => {
            totalqty = totalqty + Number(ele.qty);
            totalpricedel = totalpricedel + ele.pricedel;
            totaldiscount = totaldiscount + (Number(ele.discount.toFixed(2)) * ele.qty);
            totalprice = totalprice + ele.price;
            Nettotal = Nettotal + Number(ele.total);
          })
  
        }
        //create object ototal and  set total to object total  
        const objtotal = {
          'totalqut': totalqty,
          'totalpricedel': totalpricedel,
          'totaldiscount': totaldiscount,
          'totalprice': totalprice,
          'Nettotal': Nettotal
        }
        settotal(objtotal);
        localStorage.setItem('objtotal', JSON.stringify(objtotal))
  
      }
      // when quantity = 0
      else {
        let copy = [...orderlist]
        copy = copy.filter(
          (Item, id) => order.itemID != Item.itemID
        )
        props.SetOrderItemLst(copy)
  
        copy.forEach((ele) => {
       totalqty = totalqty + Number(ele.qty);
          totalpricedel = totalpricedel + ele.pricedel;
          totaldiscount = totaldiscount + ele.discount;
          totalprice = totalprice + ele.price;
          if(props.regionvalue==undefined){
            Nettotal = Nettotal + ele.total ;
  
          }
          else{
            Nettotal = Nettotal + ele.total + props.regionvalue.deliveryFees;
  
          }
        })
        const objtotal = {
          'totalqut': totalqty,
          'totalpricedel': totalpricedel,
          'totaldiscount': totaldiscount * totalqty,
          'totalprice': totalprice,
          'Nettotal': Nettotal
        }
        settotal(objtotal);
        localStorage.setItem('objtotal', JSON.stringify(objtotal))
  
      }
    }
    else{
      const objorder = {
        'itemID': order.itemID,
        'qty': order.itemQty,
        'itemName': order.itemName,
        'pricedel': order.itemSalesPrice * order.itemQty,
        'price': order.itemDiscountedPrice * order.itemQty,
        'discount': order.itemSalesPrice - order.itemDiscountedPrice,
        "total": order.itemDiscountedPrice,
        "netprice": order.itemDiscountedPrice,
        'deliveryOrder': props.regionvalue.deliveryFees,
        "itemMaximumOrderQty":order.itemMaximumOrderQty,
        "itemEqvUnts":order.itemEqvUnts,
        "defaultUnt":order.defaultUnt

  
      }
      let totalpricedel = 0;
      let totalqty = 0;
      let totalprice = 0;
      let totaldiscount = 0;
      let Nettotal = 0;
      //condition of quantaity
      if (order.itemQty != 0) {
        //set the order in temporary array and slice orderlist 
        let index = orderlist.findIndex(orderlist => orderlist.itemID === order.itemID);
        if (index !== -1) {
          let temporaryarray = orderlist.slice();
          //asign temporary arry of index to orderdata 
          temporaryarray[index]["qty"] = order.itemQty;
          temporaryarray[index]["pricedel"] = order.itemSalesPrice ;
          temporaryarray[index]["total"] = order.itemQty * order.itemDiscountedPrice;
          temporaryarray[index]["price"] = order.itemDiscountedPrice ;
          temporaryarray[index]["netprice"] = order.itemDiscountedPrice;
          temporaryarray[index]["itemMaximumOrderQty"] = order.itemMaximumOrderQty;
          temporaryarray[index]["itemEqvUnts"] =order.itemEqvUnts;

  
          //loop for temporary array to show the data 
          temporaryarray.forEach((ele) => {
            totalqty = totalqty + Number(ele.qty);
            totalpricedel = totalpricedel + ele.pricedel;
            totaldiscount = totaldiscount + (ele.discount * ele.qty);
            totalprice = totalprice + ele.price;
            Nettotal = Nettotal + Number(ele.total);
          })
          // if quantaity !=-1 
        } else {
          const NeworderLst = [...orderlist, objorder];
          props.SetOrderItemLst(NeworderLst)
          //set te data of neworderlist and loop to that 
          NeworderLst.forEach((ele) => {
            totalqty = totalqty + Number(ele.qty);
            totalpricedel = totalpricedel + ele.pricedel;
            totaldiscount = totaldiscount + (Number(ele.discount.toFixed(2)) * ele.qty);
            totalprice = totalprice + ele.price;
            Nettotal = Nettotal + Number(ele.total);
          })
  
        }
        //create object ototal and  set total to object total  
        const objtotal = {
          'totalqut': totalqty,
          'totalpricedel': totalpricedel,
          'totaldiscount': totaldiscount,
          'totalprice': totalprice,
          'Nettotal': Nettotal
        }
        settotal(objtotal);
        localStorage.setItem('objtotal', JSON.stringify(objtotal))
  
      }
      // when quantity = 0
      else {
        let copy = [...orderlist]
        copy = copy.filter(
          (Item, id) => order.itemID != Item.itemID
        )
        props.SetOrderItemLst(copy)
  
        copy.forEach((ele) => {
       totalqty = totalqty + Number(ele.qty);
          totalpricedel = totalpricedel + ele.pricedel;
          totaldiscount = totaldiscount + ele.discount;
          totalprice = totalprice + ele.price;
          if(props.regionvalue.deliveryFees==undefined){
            Nettotal = Nettotal + ele.total;
  
          }
          else{
            Nettotal = Nettotal + ele.total + props.regionvalue.deliveryFees;
  
          }
        })
        const objtotal = {
          'totalqut': totalqty,
          'totalpricedel': totalpricedel,
          'totaldiscount': totaldiscount * totalqty,
          'totalprice': totalprice,
          'Nettotal': Nettotal
        }
        settotal(objtotal);
        localStorage.setItem('objtotal', JSON.stringify(objtotal))
  
      }
    }
    // onject set value of oreder 
  
    //define total to use it in final of table 
   
  }

  const orderdataInpop = (order) => {
    ///define object of order and set the value 
    const orderlist = props.OrderItemLst
    // onject set value of oreder 
  if(props.regionvalue==undefined){
    const objorder = {
      'itemID': order.itemID,
      'qty': order.qty,
      'itemName': order.itemName,
      'pricedel': order.pricedel ,
      'price': order.price,
      'discount': order.pricedel - order.price,
      "total": order.price * order.qty,
      "netprice": order.pricedel,
      'deliveryOrder': props.headerObj.defaultDeliveryPrice,
      "defaultUnt":order.defaultUnt,
      "itemEqvUnts":order.itemEqvUnts,
      
    }
    //define total to use it in final of table 
    let totalpricedel = 0;
    let totalqty = 0;
    let totalprice = 0;
    let totaldiscount = 0;
    let Nettotal = 0;
    //condition of quantaity
    if (order.itemQty != 0) {
      //set the order in temporary array and slice orderlist 
      let index = orderlist.findIndex(orderlist => orderlist.itemID === order.itemID);
      if (index !== -1) {
        let temporaryarray = orderlist.slice();
        //asign temporary arry of index to orderdata 
        temporaryarray[index]["qty"] = order.qty;
        temporaryarray[index]["pricedel"] = order.pricedel ;
        temporaryarray[index]["total"] = order.qty * order.price;
        temporaryarray[index]["price"] = order.price ;
        temporaryarray[index]["netprice"] = order.price;
                temporaryarray[index]["itemEqvUnts"] = order.itemEqvUnts;

        //loop for temporary array to show the data 
        temporaryarray.forEach((ele) => {
          totalqty = totalqty + Number(ele.qty);
          totalpricedel = totalpricedel + ele.pricedel;
          totaldiscount = totaldiscount + (ele.discount * ele.qty);
          totalprice = totalprice + ele.price;
          Nettotal = Nettotal + ele.total;
        })
        // if quantaity !=-1 
      } else {
        const NeworderLst = [...orderlist, objorder];
        props.SetOrderItemLst(NeworderLst)
        //set te data of neworderlist and loop to that 
        NeworderLst.forEach((ele) => {
          totalqty = Number (totalqty )+ Number(ele.qty);
          totalpricedel = totalpricedel + ele.pricedel;
          totaldiscount = totaldiscount + (Number(ele.discount.toFixed(2)) * ele.qty);
          totalprice = totalprice + ele.price;
          Nettotal = Number(Nettotal )+ Number( ele.total);
        })

      }
      //create object ototal and  set total to object total  
      const objtotal = {
        'totalqut': totalqty,
        'totalpricedel': totalpricedel,
        'totaldiscount': totaldiscount,
        'totalprice': totalprice,
        'Nettotal': Nettotal
      }
      settotal(objtotal);
    }
    // when quantity = 0
    else {
      let copy = [...orderlist]
      copy = copy.filter(
        (Item, id) => order.itemID != Item.itemID
      )
      props.SetOrderItemLst(copy)

      copy.forEach((ele) => {
        totalqty = totalqty + ele.qty;
        totalpricedel = totalpricedel + ele.pricedel;
        totaldiscount = totaldiscount + ele.discount;
        totalprice = totalprice + ele.price;
        if(props.regionvalue.deliveryFees==undefined){
          Nettotal = Nettotal + ele.total ;

        }
        else{
          Nettotal = Nettotal + ele.total + props.regionvalue.deliveryFees;

        }
      })
      const objtotal = {
        'totalqut': totalqty,
        'totalpricedel': totalpricedel,
        'totaldiscount': totaldiscount * totalqty,
        'totalprice': totalprice,
        'Nettotal': Nettotal
      }
      settotal(objtotal);
    }
  }
  else{
    const objorder = {
      'itemID': order.itemID,
      'qty': order.qty,
      'itemName': order.itemName,
      'pricedel': order.pricedel * order.qty,
      'price': order.price * order.qty,
      'discount': order.pricedel - order.price,
      "total": order.price,
      "netprice": order.price,
      'deliveryOrder': props.regionvalue.deliveryFees
    }
    //define total to use it in final of table 
    let totalpricedel = 0;
    let totalqty = 0;
    let totalprice = 0;
    let totaldiscount = 0;
    let Nettotal = 0;
    //condition of quantaity
    if (order.itemQty != 0) {
      //set the order in temporary array and slice orderlist 
      let index = orderlist.findIndex(orderlist => orderlist.itemID === order.itemID);
      if (index !== -1) {
        let temporaryarray = orderlist.slice();
        //asign temporary arry of index to orderdata 
        temporaryarray[index]["qty"] = order.qty;
        temporaryarray[index]["pricedel"] = order.pricedel ;
        temporaryarray[index]["total"] = order.qty * order.price;
        temporaryarray[index]["price"] = order.price ;
        temporaryarray[index]["netprice"] = order.price;
                        temporaryarray[index]["itemEqvUnts"] = order.itemEqvUnts;

        //loop for temporary array to show the data 
        temporaryarray.forEach((ele) => {
          totalqty = totalqty + Number(ele.qty);
          totalpricedel = totalpricedel + ele.pricedel;
          totaldiscount = totaldiscount + (ele.discount * ele.qty);
          totalprice = totalprice + ele.price;
          Nettotal = Nettotal + ele.total;
        })
        // if quantaity !=-1 
      } else {
        const NeworderLst = [...orderlist, objorder];
        props.SetOrderItemLst(NeworderLst)
        //set te data of neworderlist and loop to that 
        NeworderLst.forEach((ele) => {
          totalqty = Number (totalqty )+ Number(ele.qty);
          totalpricedel = totalpricedel + ele.pricedel;
          totaldiscount = totaldiscount + (Number(ele.discount.toFixed(2)) * ele.qty);
          totalprice = totalprice + ele.price;
          Nettotal = Number(Nettotal )+ Number( ele.total);
        })

      }
      //create object ototal and  set total to object total  
      const objtotal = {
        'totalqut': totalqty,
        'totalpricedel': totalpricedel,
        'totaldiscount': totaldiscount,
        'totalprice': totalprice,
        'Nettotal': Nettotal
      }
      settotal(objtotal);
    }
    // when quantity = 0
    else {
      let copy = [...orderlist]
      copy = copy.filter(
        (Item, id) => order.itemID != Item.itemID
      )
      props.SetOrderItemLst(copy)

      copy.forEach((ele) => {
        totalqty = totalqty + ele.qty;
        totalpricedel = totalpricedel + ele.pricedel;
        totaldiscount = totaldiscount + ele.discount;
        totalprice = totalprice + ele.price;
        if(props.regionvalue.deliveryFees==undefined){
          Nettotal = Nettotal + ele.total ;

        }
        else{
          Nettotal = Nettotal + ele.total + props.regionvalue.deliveryFees;

        }
      })
      const objtotal = {
        'totalqut': totalqty,
        'totalpricedel': totalpricedel,
        'totaldiscount': totaldiscount * totalqty,
        'totalprice': totalprice,
        'Nettotal': Nettotal
      }
      settotal(objtotal);
    }
  }

  }

  // function to delete row if quantity = 0
  // const deleterow = (Itemid)=>{
  //   let copy = [...orderlist]
  //   copy =copy.filter(
  //     (order,Itemid) => Itemid != order.id
  //   )
  //   props.SetOrderItemLst(copy)
  // }
  /////////////function button change quntaty////////////////
  const btnonclick = (Item, Sign, Objid) => {
    ChangeQty(Item, Sign)
  }
  const [comment, setMessage] = useState('');

  const handleMessageChange = event => {
    // 👇️ access textarea value
    const x = event.target.value
    setMessage(x);
    // console.log(x)
  };
  //  document.addEventListener('DOMContentLoaded', function() {
  //   // Find the tallest height among all h6 tags
  //   let maxHeight = 0;
  //   const detailsElements = document.querySelectorAll('.details');

  //   detailsElements.forEach(function(element) {
  //     maxHeight = Math.max(maxHeight, element.offsetHeight);
  //   });

  //   // Set the same height for all h6 tags
  //   detailsElements.forEach(function(element) {
  //     element.style.height = maxHeight + 'px';
  //   });
  // });
  ////////section id ////
  // control the card length
  $(document).ready(function () {
    // Find the tallest height among all h6 tags
    let maxHeight = 0;
    $('.card-title').each(function () {
      maxHeight = Math.max(maxHeight, $(this).outerHeight());
    });
    
    // Set the same height for all h6 tags
    $('.card-title').css('height', maxHeight + 'px');
  });
  // end control the card length
 
  // // Update state with incremented value
  const changequt = (Item) => {
    if (Item.itemQty == 0)
      ChangeQty(Item, '+');
    else
      ChangeQty(Item, '0');
  }

   // Update state with incremented value
   function findSectionID(sections, itemID) {
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const items = section.items;
  
      for (let j = 0; j < items.length; j++) {
        const item = items[j];
        if (item.itemID === itemID) {
          return section.sectionID;
        }
      }
    }
  
    return null; // Item ID not found
  }
  const zeroitem2 = (Item, section) => {
  //   $("#Allcontainer").css("background", "white");

  //   $('body').css({
  //     overflow: 'scroll',
  //     height: '100%'
  // });

    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false;

  $("#itemop").css("opacity", "1");
  $("#Headop").css("opacity", "1");
  $("#sliderOp").css("opacity", "1");
  $("#recordOp").css("opacity", "1");
  $("#formOp").css("opacity", "1");
  $("#footer").css("opacity", "1");
  $("#itemop").css("pointer-events", "visible");

const sectionID = findSectionID(section, Item.itemID);
// console.log("Section ID:", sectionID);

    let index = Section.findIndex(Section => Section.sectionID === sectionID);
    // console.log(index)
    let itemsec = Section[index].items
    let index2 = itemsec.findIndex(itemsec => itemsec.itemID === Item.itemID);

    setpopupprice(false)
    itemsec[index2].itemQty = 0
    orderdata(itemsec[index2]);
    setarray([])

  }
  function setbtnval1(value) {
 
    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

      console.log(array)
   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }
    console.log(array.indexOf(".00"));
    // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
    //   console.log(previousSecondElementOfTheArray)

 
  }
   }
  }
  function setbtnval2(value) {
   
    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

      console.log(array)
   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }
    console.log(array.indexOf(".00"));
    // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
    //   console.log(previousSecondElementOfTheArray)

 
  }
   }
  }
  function setbtnval3(value) {

    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

      console.log(array)
   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }
    console.log(array.indexOf(".00"));
    // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
    //   console.log(previousSecondElementOfTheArray)

 
  }
   }
  }
  function setbtnval4(value) {
 
    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

      console.log(array)
   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }
    console.log(array.indexOf(".00"));
    // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
    //   console.log(previousSecondElementOfTheArray)

 
  }
   }
  }
  function setbtnval5(value) {

    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

      console.log(array)
   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }
    console.log(array.indexOf(".00"));
    // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
    //   console.log(previousSecondElementOfTheArray)

 
  }
   }
      // console.log(array.findIndex(isLargeNumber));
      // const myArrayS = array.split(".");
      // value
      // console.log("myArray",myArrayS)
    
  }
  function setbtnval6(value) {


    if(array.length>3){
      Swal.fire("للقطع لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

      console.log(array)
   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }
    console.log(array.indexOf(".00"));
    // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
    //   console.log(previousSecondElementOfTheArray)

 
  }
   }

  }
  function setbtnval7(value) {

 
    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

      console.log(array)
   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }
    console.log(array.indexOf(".00"));
    // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
    //   console.log(previousSecondElementOfTheArray)

 
  }
   }

  }
  function setbtnval8(value) {

   
    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

      setarray(current => [...current, value]);
      setshowarray(true)
    }
  }
  function setbtnval9(value) {

   
    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

      console.log(array)
   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }
    console.log(array.indexOf(".00"));
    // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
    //   console.log(previousSecondElementOfTheArray)

 
  }
   }
  }
  function setbtnval10(value) {
    // console.log("0", 0)
    // console.log(array)
    if (array.length == 0) {
      setarray([])
      Swal.fire({
        title: 'يجب ادخال كميه  ',
        icon: 'warning',

        confirmButtonText: 'تم'
      })
    }
    else {
    
      if(array.length>3){
        Swal.fire("لقد تعديت الحد الاقصي")
      }
      else{
        setbtnvalue(btnval + value)
  
        console.log(array)
     
     if(array.length ==0){
      setarray(current => [...current, value]);
      setshowarray(true)
     
     }
     else{
      const indexofDel= array.indexOf(".00")
      if(indexofDel==-1){
        setarray(current => [...current,value]);
        setshowarray(true)
      }
      else{
        array[indexofDel]= "." +value
  
        setarray(current => [...current]);
        setshowarray(true)
      }
      console.log(array.indexOf(".00"));
      // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
      //   console.log(previousSecondElementOfTheArray)
  
   
    }
     }
    }


  }
  function backspacebtn(){
console.log("array.len",array)
array.pop()
console.log("array.len2",array)
setarray(current => [...current]);
console.log("array.len3",array)




  }
  const createScreenshotPDF = () => {
    const MARGIN = 2;

    // get the content element
    document.getElementById('orderprint2').style.display="block"
    const content = document.getElementById('orderprint2');
  
    // get content size
    const width = 397;
    const height = content.clientHeight;
  
    // call html2canvas to save the screenshot to a pdf file
    html2canvas(content, {
      scrollX: 0, // scroll to X origin
      scrollY: 5, // scroll to Y origin
      scale: 5, // 5 is quality scale
    }).then(function (canvas) {
      // convert the canvas conent to a Data URI/URL
      var img = canvas.toDataURL('image/jpeg', 5); // 5 is quality scale
      var doc = new jsPDF({
        orientation: 'portrait',
        unit: 'px', // set pixel as unit
        format: [width + MARGIN * 1, height + MARGIN * 1],
      });
  
      // add canvas as an image
      doc.addImage(img, 'JPEG', MARGIN, MARGIN, width, height);
  
      // save the content to screenshot.pdf
      doc.save('الاوردر.pdf');
    });
    document.getElementById('orderprint2').style.display="none"

  };

async function getBase64ImageFromUrl(imageUrl) {
  var res = await fetch(imageUrl);
  var blob = await res.blob();

  return new Promise((resolve, reject) => {
    var reader  = new FileReader();
    reader.addEventListener("load", function () {
        resolve(reader.result);
    }, false);

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  })
}
  const createScreenshotPDFMob = () => {
  const MARGIN = 2;
  // get the content element
  const content = document.getElementById('printablediv');
   content.style.display="block";

// console.log(content.offsetWidth)

// console.log(content.clientWidth)

  // // get content size
  const width = content.offsetWidth ;
  const height = content.clientHeight;
  // var opt = {
  //   margin:       0.2,
  //   filename:     'اوردر.pdf',
  //   image:        { type: 'jpeg', quality: 0.98 },
  //   jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' }
  // };

  // html2pdf(content, opt).then(() => {
  //   content.style.display="none";

  // });

  // call html2canvas to save the screenshot to a pdf file
  html2canvas(content, {
    scrollX: 0, // scroll to X origin
    scrollY: 5, // scroll to Y origin
    scale: 5, // 5 is quality scale
  }).then(function (canvas) {
    // convert the canvas conent to a Data URI/URL
    var img = canvas.toDataURL('image/jpeg', 5); // 5 is quality scale
    var doc = new jsPDF({
      orientation: 'portrait',
      unit: 'px', // set pixel as unit
      format: [width + MARGIN * 1, height + MARGIN * 1],
    });

    // add canvas as an image
    doc.addImage(img, 'JPEG', MARGIN, MARGIN, width, height);

    // save the content to screenshot.pdf
    if(getOperatingSystem() != "ios"){
     doc.save('اوردر.pdf');
    }
    else{
       // Display the PDF in the browser
       const pdfData = doc.output('blob');
       const blobUrl = URL.createObjectURL(pdfData);
       window.open(blobUrl);
    }
  });
  content.style.display="none";
};
  // const createScreenshotPDFMob = () => {
  //   const MARGIN = 2;
  //   // get the content element
  //   document.getElementById('printablediv').style.display="block"
  //   const content = document.getElementById('printablediv');
  
  // console.log(content.offsetWidth)
  
  // console.log(content.clientWidth)
  
  //   // get content size
  //   const width = content.offsetWidth ;
  //   const height = content.clientHeight;
  
  //   // call html2canvas to save the screenshot to a pdf file
  //   html2canvas(content, {
  //     scrollX: 0, // scroll to X origin
  //     scrollY: 5, // scroll to Y origin
  //     scale: 5, // 5 is quality scale
  //   }).then(function (canvas) {
  //     // convert the canvas conent to a Data URI/URL
  //     var img = canvas.toDataURL('image/jpeg', 5); // 5 is quality scale
  //     var doc = new jsPDF({
  //       orientation: 'portrait',
  //       unit: 'px', // set pixel as unit
  //       format: [width + MARGIN * 1, height + MARGIN * 1],
  //     });
  
  //     // add canvas as an image
  //     doc.addImage(img, 'JPEG', MARGIN, MARGIN, width, height);
  
  //     // save the content to screenshot.pdf
  //     doc.save('الطلبيه.pdf');
  //   });
  // };
  function fDecimalNumber(value){
    const str =value.toString()
    // const str = value.replace(/^0+/, '');
    const myArray = str.split("0");
    // value
    console.log("myArray",myArray)
  ///////////////
    console.log("myArray",array)
  console.log("sssss",  (array.toString().includes(".")))
    if(array.length==1){
    let index = array.findIndex(array =>array === myArray[1]);
  
    if(index===-1){
      
      value=(parseFloat(myArray[1]))
      myArray[1]=value+parseFloat(array[0])
      array[0]=0 + myArray[1]
    
    
    
          setarray(current => [...array]);
          localStorage.setItem("btnval",myArray[1])
    
    }
    else{
      array.splice(index);
    
      value=parseFloat(myArray[1])+parseFloat(value)
      const str1 =value.toString()
    
      const array2=str1.split("0")
      // array.splice(index,value);
      setarray(current => [...array,value]);
      localStorage.setItem("btnval",myArray[1])
  
    
    }
  }
  else{
    let index = array.findIndex(array =>array === localStorage.getItem("btnval"));
  
    if(index===-1){
      if(array.toString().includes(".")==true){
        array.indexOf(".");
        const myArray = str.split("0");
        value=parseFloat(myArray[1])+parseFloat(value)
     
        let sum = "";

        array.forEach(item => {
            sum += item;
        });
        
        console.log(sum);
    
    let thevalue=(parseFloat(myArray[1])+parseFloat(sum)).toString();
        setarray(current => [thevalue]);
      }
      else{
        value=parseFloat(myArray[1])
        console.log(array.length)
        if(array.length==0){
          let zero=0;
          myArray[1]=zero.toString() + myArray[1]
      
      
          setarray(current => [...array,myArray[1]]);
        }
        else{
          let zero="";
          myArray[1]=zero.toString() + myArray[1]
      
      
          setarray(current => [...array,myArray[1]]);
        }
   
      }
  
          
    
    }
    else{
      array.splice(index);
    
      value=parseFloat(localStorage.getItem("btnval"))+parseFloat(value)
      const str1 =value.toString()
    
      const array2=str1.split("0")
      // array.splice(index,value);
      setarray(current => [...array,array2[1]]);
    
    }
  }
  
  
  
  
  //     if(myArray[i].includes(".")){
  //       let index2 = myArray[i].findIndex(myArray[i]);
  //       console.log(index2)
  
      
  //     const x=  parseFloat(myArray[i])
  //     console.log(" myArray.includes", x)
  //     setarray(current => [...current, myArray[i]]);
  // console.log("a",array)
  //     }
  
    
    // myArray.includes(".");
    // console.log(" myArray.includes", myArray[1].includes("."))
  
    //   setshowarray(true)
    //   console.log("myArray",array)
  
  
  }
function showdropdown(id){
  if(dropdowneqv==false){
    setdropdowneqv(true)
    setiddrodwn(id)
    
  }
  else{
    setdropdowneqv(false)
  }
  }
  const seteqv= (item,EqvId,EqvName) => {
    // let index1 = item.findIndex(item => item.Id === id);
    seteqvitem(EqvName)
    seteqvid(EqvId)
    seteqvname(EqvName)
    item.eqvID=EqvId
  item.eqvName=EqvName
    orderdata(item);
  //   let index2 = itemeqv.findIndex(itemeqv => itemeqv.eqvID === EqvId);
  //   item.itemEqvUnts[index2].
  }
function fDecimalNumberpoint(value){
  const str =value.toString()
  // const str = value.replace(/^0+/, '');
  const myArray = str.split("*");
  // value
  console.log("myArray",myArray)
///////////////
  console.log("myArray",array)
  console.log(array.includes("."))
if(array.length==1 )
{ if( (array[0].toString()).includes(".")){
  let index = array.findIndex(array =>array === myArray[1]);

  if(index===-1){
    
    value=parseFloat(myArray[1])
   
  
  
  
        setarray(current => [...array]);
        localStorage.setItem("btnval",myArray[1])
  
  }
  else{
 
  if(array[0]==".00"){
   
    setarray(current => [...array]);
    localStorage.setItem("btnval",myArray[1])

  }
  else{
    array.splice(index);
    value=parseFloat(myArray[1])+parseFloat(value)
    const str1 =value.toString()
  
    const array2=str1.split("0")
    // array.splice(index,value);
    setarray(current => [...array,array2[1]]);
    localStorage.setItem("btnval",myArray[1])
  }


  
  }
}
else{
  let index = array.findIndex(array =>array === myArray[1]);

  if(index===-1){
    parseFloat(array)
    value=parseFloat(myArray[1])
   
  
  
  
        setarray(current => [...array,myArray[1]]);
        localStorage.setItem("btnval",myArray[1])
  
  }
  else{
    array.splice(index);
  
    value=parseFloat(myArray[1])+parseFloat(value)
    const str1 =value.toString()
  
    const array2=str1.split("0")
    // array.splice(index,value);
    setarray(current => [...array,array2[1]]);
    localStorage.setItem("btnval",myArray[1])

  
  }
}

}
else{
  let index = array.findIndex(array =>array === localStorage.getItem("btnval"));

  if(index===-1){
    
    value=parseFloat(0+myArray[1])
   
  console.log("array",parseFloat(array))
  if(array.length==0){
    setarray(current => [...array,myArray[1]]);

  }
  else{
    setarray(current => [...array]);
  }
  
     
        
  
  }
  else{
    array.splice(index);
  
    const str1 =value.toString()
  
    const array2=str1.split("0")
    value=parseFloat(localStorage.getItem("btnval"))+parseFloat(value)

    // array.splice(index,value);
    setarray(current => [...array,array2[1]]);
  
  }
}




//     if(myArray[i].includes(".")){
//       let index2 = myArray[i].findIndex(myArray[i]);
//       console.log(index2)

    
//     const x=  parseFloat(myArray[i])
//     console.log(" myArray.includes", x)
//     setarray(current => [...current, myArray[i]]);
// console.log("a",array)
//     }

  
  // myArray.includes(".");
  // console.log(" myArray.includes", myArray[1].includes("."))

  //   setshowarray(true)
  //   console.log("myArray",array)


}
  return (
    <div>
      <div className='container-fluid' id='itemop'  style={{direction:"rtl",overflow:"hidden"}}>

        <div className='row'>
          {Section && Section.length > 0 && Section.map((SectionObj, index) => (
            <div>
              <div>
                <div
                  className='my-1 mt-2'
                // style={{height:"303px"}}
                >
                  <img className='w-100' src={SectionObj.sectionBanner} />
                </div>
                {/* <div style={{backgroundImage:`url(${SectionObj.sectionBanner})`,maxHeight:"250px" ,width:"100%",height:"100px"}}></div> */}

                <div className='row mt-3 g-2 '>
                  {Section && Section.length > 0 && SectionObj.items.map((itemObj, index) => (

                    <div class=" col-6  col-lg-3 col-md-4 ">{
                      <div class="shadow  rounded d-flex flex-column justify-content-center px-2 align-items-center card-body " style={{ backgroundColor: "white" }}>

                        {/*design to card */}

                        <div style={{
                          backgroundImage: `url(${itemObj.itemImage})`,
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          height: "160px",
                          width: '150px',
                          direction:"ltr",
                          backgroundPosition: 'center',
                          marginTop: "4%",

                        }} onClick={() => changequt(itemObj)}>
                          <button style={{  'width': '50%', 'height': '25%', 'border': '0', 'z-index': '100' }} className='btn checktrue'
                            onChange={() => changequt(itemObj)} id='inputcheckqut'>
                            {itemObj.itemQty > 0 && <AiOutlineCheck className='fs-1' style={{ 'color': 'red', 'width': '50px', 'height': '59px', 'margin-top': '-10px' }}></AiOutlineCheck>}  </button>
                          {/* props.headerObj.saleTagColor */}

                          {itemObj.itemDiscountAmount == 0 ?
                            <div style={{ "margin-top": '-10%', 'margin-left': '-9%' }}>

                            </div>


                            :
                            <div className='flag ' style={{ 'position': 'absolute', 'background-color': props.headerObj.saleTagColor }}>sale <br></br> {itemObj.itemDiscountAmount}<span>جم</span>
                            </div>

                          }

                        </div>
                        <div className='card-body details' onClick={() => changequt(itemObj)}>

                          <h5 className='card-title pt-2 '  id='cardname' style={{ 'color': props.headerObj.fontcolor }} >{itemObj.itemName}</h5>


                          {/* <h5 className='card-title' id='cardname' >{itemObj.average}</h5> */}

                          {itemObj.itemDiscountAmount == 0 ? <div className=" divbody mt-0">
                            <h4 className='price' id='price' style={{ color: "" }}>  {itemObj.itemDiscountedPrice}</h4><span className='price '>جم</span> 

                          </div>
                            :
                            <div className=" divbody mt-0">
                              {/*tolocalstring => this function to make (,) after 3 number */}
                              <h4 className='price ' id='price' dir='rtl'>{itemObj.itemDiscountedPrice} جم</h4>
                            </div>}

                        </div>
                        <div className=' w-100 btn d-flex justify-content-between my-1 ' style={{ 'background': props.headerObj.buttonColor }}>
                          {/* <div className=''> */}
                          <div className=' fs-2    text-white' onClick={() => btnonclick(itemObj, '+')} >
                            {/* &#43; is add icon */}
                            <span className='margespan '>&#43;</span>
                          </div>
                          
                          <div className='fs-4 text-white ' style={{direction:"ltr"}} id='quantity' onClick={() => showpopuppriceitem(itemObj)} >
                            {/* { props.OrderItemLst.length > 0? props.OrderItemLst.map((ele )=>{
                            if(ele.itemID == itemObj.itemID){
                              itemObj.itemQty = Number(ele.qty)
                              return itemObj.itemQty 
                            }
                            else{
                              return itemObj.itemQty 
                            }
                          })
                          :
                          itemObj.itemQty === 0 ||itemObj.itemQty === '0' ? 0 :  itemObj.itemQty} */}
                          {itemObj.itemQty}
                          </div>
                          <div className=' text-white fs-2    ' onClick={() => ChangeQty(itemObj, '-')}>
                            &#8722;</div>
                          {/* </div> */}

                          {/* <div className=''> */}

                          {/* </div> */}
                        </div>
                      </div>
                    }
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>




      </div>


      <div className='container-fluid'>
        <div className='row justify-content-center '>
          <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
            <div className='container-fluid bord'>

              <div className='row bg-primary justify-content-center mt-1 roundedtitle'>
                <h1 className='col-1'>  <BsCart2 className=' text-white fs-1 mt-2' />  </h1>
                <h1 className='text-light col-10 text-center fontmaaray mt-1'>اوردر</h1>
              </div>
              {/*table of order item */}
              <div className='row fontmaaray justify-content-center mt-3' id='myTable'>

                <h6 className='col-2 fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>كميه</h6>
                <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>صنف</h6>
                <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>خصم</h6>
                <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>سعر</h6>
                <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>إجمالي</h6>

                {/*maping from list to show order details [name, quntity,total discount,total ] */}
                {props.OrderItemLst.map(order => (


                  <div className='row justify-content-center fontmaaray position-relative  my-2' >
                   <button id="printButton" className='btn btn-danger d-block d-md-none py-0 position-absolute' onClick={() => showpopupprice(order)} style={{ zIndex: "5", left: "5px", top: "-5px", width: "auto" }}><EditIcon sx={{ fontSize: "1rem" }}></EditIcon></button>
                    <h6 className='col-1 fontmaaray ' style={{ 'color': props.headerObj.fontcolor,direction:"ltr" }}>{order.qty}</h6>
                    <h6 className='col-11 fontmaaray pe-4  ' style={{ 'color': props.headerObj.fontcolor, textAlign: "right" }}>{order.itemName}</h6>
                    <div className='col-5'></div>
                    <h6 className='col-2 fontmaaray  ' style={{ 'color': props.headerObj.fontcolor }}>{(Number(order.discount).toFixed(2).toLocaleString())}  </h6>
                    <h6 className='col-3 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{Number(order.netprice).toFixed(2).toLocaleString()}</h6>
                    <h6 className='col-2 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }} >{(order.total.toLocaleString())}</h6>
                    <hr className='mt-3 col-9' style={{ 'margin-right': '6%' }}></hr>

                  </div>


                ))}
                {popupprice ?

                  <div className='col-lg-3 col-md-6 col-11 card popup  p-0' id='popup' >
                    <div className='container-fluid p-0'>
                    {/* {console.log("array",array)} */}
                      <div className=' card-header w-100 p-0' style={{ height: "50px" }}>
                              {itemObj.discount == 0 ?
                                <div className='d-flex ' id={itemObj.itemID}>
                                              <div className='col-4 py-1'>
                                    <p className='fontmaaray x3' style={{ direction: 'rtl', fontSize: "22px", fontWeight: "bold", marginTop: "7px", textAlign: "right", marginRight: "12px" }}>{priceinpopupprice} جم</p>
                                  </div>
                                  <div className='col-8 p-0'>
                                 <div className='d-flex'>
<div className='col-8'>
<p className='fontmaaray x1' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl",textAlign:"left" }}>{itemObj.qty} &nbsp; {eqvitem} </p>

</div>
<div className='col-4'>

{itemObj.itemEqvUnts.map((itemObjeqv) => (
<p  id='eledropdown'  style={{borderBottom:"1px solid lightgrey",marginTop:"14%",cursor:"pointer"}}
>{itemObjeqv.eqvName}</p> ))}


</div> 

                                 </div>
                             




                                    
                                  
                                     {/* <p className='fontmaaray x1' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl" }}>{itemObj.qty}</p> */}
                                  </div>
                      
                                </div> :
                                <div className=''>
                                <div className='d-flex justify-content-between'>
                                <div className=' py-1'>
                                  
                                    <p className='fontmaaray x6' style={{ direction: 'rtl', fontSize: "22px", fontWeight: "bold", marginTop: "7px", textAlign: "right", marginRight: "12px" }}>{itemObj.netprice} جم</p>

                                  </div>
                                  <div className=' py-1'>
                                  <p className='fontmaaray x4' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl" }}>{itemObj.qty}</p>

                                  </div>
                                </div>
 


                                </div>}

                            
                          
                        </div>
                      </div>
                      <div className=' container-fluid p-2'>

                        <div className='row '>
                          <div className='col-12' style={{ fontSize: "20px" }}>
                            <p className='fontmaaray' style={{ textAlign: "center", marginTop: "8px", color: "green", fontWeight: "bold" }}>  {itemObj.itemName} </p>
                          </div>
                        </div>
                        <div className='row' style={{marginTop:"-3%",height:"40px"}}>
                          {array.length > 0?
                            <p className='fontmaaray x2 text-center' style={{ direction:"ltr",fontSize: " 22px", marginTop: "7px", fontWeight: "bold",textAlign:"left" }}> {(array)} &nbsp; {eqvitem} </p>

                          :""}
                          </div>
                          {itemObj.defaultUnt.isDecimal==true?
                          
                            <div className='d-flex' style={{height:"50px"}}>
                          <div className='col-2'>
<button className='btn btn-primary h-100 w-100' onClick={() => fDecimalNumber(FnumDecimal)} >1/8</button>

</div>
<div className='col-2' style={{marginRight:"4%"}} >
<button className='btn btn-primary w-100 h-100'  onClick={() => fDecimalNumber(SnumDecimal)} >1/4</button>

</div>
<div className='col-2' style={{marginRight:"4%"}}>
<button className='btn btn-primary w-100 h-100' onClick={() => fDecimalNumber(THnumDecimal)} >1/3</button>

</div>
<div className='col-2' style={{marginRight:"4%"}}>
<button className='btn btn-primary w-100 h-100'  onClick={() => fDecimalNumber(FOnumDecimal)}>1/2</button>

</div>

<div className='col-2' style={{marginRight:"4%"}} onClick={() => fDecimalNumberpoint("*.00")}>
  <button className='btn btn-primary w-100 h-100'>
    <RxDotFilled style={{fontSize:"26px"}}></RxDotFilled>
  </button>
</div>





                          </div>
                          
                          
                          :""}
                       
                        <div className='row mt-3' >
                          <div className='col-4 p-0' >
                            <button className=' btn btn-secondary fontmaaray' id='btn1' value={btnval} onClick={() => setbtnval1(1)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>1</button>
                          </div>
                          <div className='col-4 p-0'  >

                            <button className='btn btn-secondary fontmaaray' id='btn2' value={btnval} onClick={() => setbtnval2(2)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>2</button>

                          </div>

                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn3' onClick={() => setbtnval3(3)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>3</button>

                          </div>

                        </div>
                        <div className='row mt-2' >
                          <div className='col-4 p-0'  >

                            <button className='btn btn-secondary fontmaaray' id='btn4' onClick={() => setbtnval4(4)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>4</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray ' id='btn5' onClick={() => setbtnval5(5)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>5</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn6' onClick={() => setbtnval6(6)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>6</button>
                          </div>

                        </div>
                        <div className='row mt-2' >
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn7' onClick={() => setbtnval7(7)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>7</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn8' onClick={() => setbtnval8(8)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>8</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn9' onClick={() => setbtnval9(9)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>9</button>

                          </div>
                        </div>
                        <div className='row mt-2' >
                          <div className='col-lg-4 col-4 p-0'>
                            {console.log("a.len",array)}
                              <button className='btn btn-warning' style={{height:"100%",width:"83%"}}   onClick={backspacebtn} >
                                <IoBackspaceSharp style={{fontSize:"40px",color:"white"}}> </IoBackspaceSharp>
                                </button>
                          </div>

                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn10' onClick={() => setbtnval10(0)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>0</button>

                          </div>
                          <div className='col-lg-4 col-4 p-0'>
                            <button className='btn btn-danger fontmaaray' style={{ color: "white", cursor: "pointer", width: "82%", height: "100%" }} onClick={closepricepopup}>غلق</button>
                             
                          </div>



                        </div>
                        {itemObj.qty == 0 ? 
                        <div className='row mt-2'>
                        <div className='col-lg-12 col-12 '>
                              <button className='btn  btn-success fontmaaray' style={{ backgroundColor: "rgb(0, 128, 0)", color: "white", cursor: "pointer", width: "100%", height: "60px" }} onClick={() => showarrayleng(itemObj)}>موافق</button>

                              </div>
                        </div>
                        
                        
                         :  
                              <div className='row mt-2'>
                              <div className='col-lg-6 col-7'>
                              <button className='btn  btn-success fontmaaray' style={{ backgroundColor: "rgb(0, 128, 0)", color: "white", cursor: "pointer", width: "100%", height: "60px" }} onClick={() => showarrayleng(itemObj)}>موافق</button>

                              </div>
                              <div className='col-lg-6 col-5'>
                        <button className='btn btn-warning w-100' style={{ color: "#D10000", fontWeight: "bold",height: "60px" }} onClick={() => zeroitem2(itemObj, Section)} >
                              حذف الصنف
                            </button>

                          </div>
                        </div> }
                
                    

                      </div>
                    </div>
                  : ""}

                {/*total of order  */}


              </div>

            </div>
            <div className='container-fluid'>

              <div className='row'>
                <div className='col-7 d-flex '>
                  <h6 className=' fs-4 fontmaaray ' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>  عدد الأصناف </h6>
                  <h4 className='   fs-4  fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(props.OrderItemLst.length).toFixed(2)})</h4>
                </div>
                <div className='col-5 d-flex' >
                  <h6 className='  fs-4  fontmaaray' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}> كميه </h6>
                  <h6 className='  fs-4 fontmaaray ' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(total.totalqut).toFixed(2)})</h6>
                </div>
              </div>

              <div className='row '>
                <h6 className='col-1 fs-4 fontmaaray' style={{ 'color': props.headerObj.fontcolor }}> الوفر</h6>
                <hr className='mt-3 col-10' style={{ 'width': '60%', 'color': 'white' }}></hr>

                <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> {Number(total.totaldiscount).toFixed(2)}</h6>

              </div>
              <div className='row'>
                <h6 className='col-1 fs-4 fontmaaray' style={{  'color': props.headerObj.fontcolor }}> دليفري</h6>
                <hr className='mt-3 col-10' style={{ 'width': '60%', 'color': 'white' }}></hr>
{props.delvVal == undefined?
<h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:
<h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.delvVal).toFixed(2)}</h6>



}

              </div>
              <div className='row'>
                <h6 className='col-2 fs-4 fontmaaray' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
                <hr className='mt-3 col-7' style={{ 'color': 'white', 'width': '51.5%' }}></hr>
{props.delvVal == undefined? 
       <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal+props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:         
       <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.delvVal).toFixed(2)}</h6>
}

              </div>
            </div>




          </div>
          
          </div>

                {/* Mobile PDF Print */}
                <>
          <div className='row justify-content-center ' id='printablediv' style={{display:"none"}}>
          <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
            <div className='container-fluid bord'>

            <div className="row justify-content-center">
      
      <img src={LogoImageBase64} style={{ "width": "100px","height": "100px" }}></img>
      <div className="col-9" style={{marginTop:"-8%"}}>
        <div className="row justify-content-center headinmobile" >
      <div className="card-title magazine-title " style={{fontSize: "18px", "color":props.headerObj.headerFontColor}}>التاريخ : {moment().format('MMMM Do YYYY, h:mm:ss a')}</div>
    </div>
    </div>
      </div>  
              <div className='row bg-primary justify-content-center mt-1 roundedtitle'>
                <h1 className='col-1'>  <BsCart2 className=' text-white fs-1 mt-2' />  </h1>
                <h1 className='text-light col-10 text-center fontmaaray mt-1'>اوردر</h1>
              </div>
              {/*table of order item */}
              <div className='row fontmaaray justify-content-center mt-3' id='myTable'>

                <h6 className='col-2 fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>كميه</h6>
                <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>صنف</h6>
                <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>خصم</h6>
                <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>سعر</h6>
                <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>إجمالي</h6>

                {/*maping from list to show order details [name, quntity,total discount,total ] */}
                {props.OrderItemLst.map(order => (


                  <div className='row justify-content-center fontmaaray position-relative  my-2' >
                    <h6 className='col-1 fontmaaray ' style={{ 'color': props.headerObj.fontcolor,direction:"ltr" }}>{order.qty}</h6>
                    <h6 className='col-11 fontmaaray pe-4  ' style={{ 'color': props.headerObj.fontcolor, textAlign: "right" }}>{order.itemName}</h6>
                    <div className='col-5'></div>
                    <h6 className='col-2 fontmaaray  ' style={{ 'color': props.headerObj.fontcolor }}>{(Number(order.discount).toFixed(2).toLocaleString())}  </h6>
                    <h6 className='col-3 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{Number(order.netprice).toFixed(2).toLocaleString()}</h6>
                    <h6 className='col-2 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }} >{(order.total.toLocaleString())}</h6>
                    <hr className='mt-3 col-9' style={{ 'margin-right': '6%' }}></hr>

                  </div>


                ))}
                {popupprice ?

                  <div className='col-lg-3 col-md-6 col-11 card popup  p-0' id='popup' >
                    <div className='container-fluid p-0'>
                    {/* {console.log("array",array)} */}
                      <div className=' card-header w-100 p-0' style={{ height: "50px" }}>
                              {itemObj.discount == 0 ?
                                <div className='d-flex ' id={itemObj.itemID}>
                                              <div className='col-4 py-1'>
                                    <p className='fontmaaray x3' style={{ direction: 'rtl', fontSize: "22px", fontWeight: "bold", marginTop: "7px", textAlign: "right", marginRight: "12px" }}>{priceinpopupprice} جم</p>
                                  </div>
                                  <div className='col-8 p-0'>
                                 <div className='d-flex'>
<div className='col-8'>
<p className='fontmaaray x1' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl",textAlign:"left" }}>{itemObj.qty} &nbsp; {eqvitem} </p>

</div>
<div className='col-4'>

{itemObj.itemEqvUnts.map((itemObjeqv) => (
<p  id='eledropdown'  style={{borderBottom:"1px solid lightgrey",marginTop:"14%",cursor:"pointer"}}
>{itemObjeqv.eqvName}</p> ))}


</div> 

                                 </div>
                             




                                    
                                  
                                     {/* <p className='fontmaaray x1' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl" }}>{itemObj.qty}</p> */}
                                  </div>
                      
                                </div> :
                                <div className=''>
                                <div className='d-flex justify-content-between'>
                                <div className=' py-1'>
                                  
                                    <p className='fontmaaray x6' style={{ direction: 'rtl', fontSize: "22px", fontWeight: "bold", marginTop: "7px", textAlign: "right", marginRight: "12px" }}>{itemObj.netprice} جم</p>

                                  </div>
                                  <div className=' py-1'>
                                  <p className='fontmaaray x4' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl" }}>{itemObj.qty}</p>

                                  </div>
                                </div>
 


                                </div>}

                            
                          
                        </div>
                      </div>
                      <div className=' container-fluid p-2'>

                        <div className='row '>
                          <div className='col-12' style={{ fontSize: "20px" }}>
                            <p className='fontmaaray' style={{ textAlign: "center", marginTop: "8px", color: "green", fontWeight: "bold" }}>  {itemObj.itemName} </p>
                          </div>
                        </div>
                        <div className='row' style={{marginTop:"-3%",height:"40px"}}>
                          {array.length > 0?
                            <p className='fontmaaray x2 text-center' style={{ direction:"ltr",fontSize: " 22px", marginTop: "7px", fontWeight: "bold",textAlign:"left" }}> {(array)} &nbsp; {eqvitem} </p>

                          :""}
                          </div>
                          {itemObj.defaultUnt.isDecimal==true?
                          
                            <div className='d-flex' style={{height:"50px"}}>
                          <div className='col-2'>
<button className='btn btn-primary h-100 w-100' onClick={() => fDecimalNumber(FnumDecimal)} >1/8</button>

</div>
<div className='col-2' style={{marginRight:"4%"}} >
<button className='btn btn-primary w-100 h-100'  onClick={() => fDecimalNumber(SnumDecimal)} >1/4</button>

</div>
<div className='col-2' style={{marginRight:"4%"}}>
<button className='btn btn-primary w-100 h-100' onClick={() => fDecimalNumber(THnumDecimal)} >1/3</button>

</div>
<div className='col-2' style={{marginRight:"4%"}}>
<button className='btn btn-primary w-100 h-100'  onClick={() => fDecimalNumber(FOnumDecimal)}>1/2</button>

</div>

<div className='col-2' style={{marginRight:"4%"}} onClick={() => fDecimalNumberpoint("*.00")}>
  <button className='btn btn-primary w-100 h-100'>
    <RxDotFilled style={{fontSize:"26px"}}></RxDotFilled>
  </button>
</div>





                          </div>
                          
                          
                          :""}
                       
                        <div className='row mt-3' >
                          <div className='col-4 p-0' >
                            <button className=' btn btn-secondary fontmaaray' id='btn1' value={btnval} onClick={() => setbtnval1(1)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>1</button>
                          </div>
                          <div className='col-4 p-0'  >

                            <button className='btn btn-secondary fontmaaray' id='btn2' value={btnval} onClick={() => setbtnval2(2)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>2</button>

                          </div>

                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn3' onClick={() => setbtnval3(3)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>3</button>

                          </div>

                        </div>
                        <div className='row mt-2' >
                          <div className='col-4 p-0'  >

                            <button className='btn btn-secondary fontmaaray' id='btn4' onClick={() => setbtnval4(4)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>4</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray ' id='btn5' onClick={() => setbtnval5(5)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>5</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn6' onClick={() => setbtnval6(6)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>6</button>
                          </div>

                        </div>
                        <div className='row mt-2' >
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn7' onClick={() => setbtnval7(7)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>7</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn8' onClick={() => setbtnval8(8)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>8</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn9' onClick={() => setbtnval9(9)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>9</button>

                          </div>
                        </div>
                        <div className='row mt-2' >
                          <div className='col-lg-4 col-4 p-0'>
                            {console.log("a.len",array)}
                              <button className='btn btn-warning' style={{height:"100%",width:"83%"}}   onClick={backspacebtn} >
                                <IoBackspaceSharp style={{fontSize:"40px",color:"white"}}> </IoBackspaceSharp>
                                </button>
                          </div>

                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn10' onClick={() => setbtnval10(0)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>0</button>

                          </div>
                          <div className='col-lg-4 col-4 p-0'>
                            <button className='btn btn-danger fontmaaray' style={{ color: "white", cursor: "pointer", width: "82%", height: "100%" }} onClick={closepricepopup}>غلق</button>
                             
                          </div>



                        </div>
                        {itemObj.qty == 0 ? 
                        <div className='row mt-2'>
                        <div className='col-lg-12 col-12 '>
                              <button className='btn  btn-success fontmaaray' style={{ backgroundColor: "rgb(0, 128, 0)", color: "white", cursor: "pointer", width: "100%", height: "60px" }} onClick={() => showarrayleng(itemObj)}>موافق</button>

                              </div>
                        </div>
                        
                        
                         :  
                              <div className='row mt-2'>
                              <div className='col-lg-6 col-7'>
                              <button className='btn  btn-success fontmaaray' style={{ backgroundColor: "rgb(0, 128, 0)", color: "white", cursor: "pointer", width: "100%", height: "60px" }} onClick={() => showarrayleng(itemObj)}>موافق</button>

                              </div>
                              <div className='col-lg-6 col-5'>
                        <button className='btn btn-warning w-100' style={{ color: "#D10000", fontWeight: "bold",height: "60px" }} onClick={() => zeroitem2(itemObj, Section)} >
                              حذف الصنف
                            </button>

                          </div>
                        </div> }
                
                    

                      </div>
                    </div>
                  : ""}

                {/*total of order  */}


              </div>

            </div>
            <div className='container-fluid'>

              <div className='row'>
                <div className='col-7 d-flex '>
                  <h6 className=' fs-4 fontmaaray ' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>  عدد الأصناف </h6>
                  <h4 className='   fs-4  fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(props.OrderItemLst.length).toFixed(2)})</h4>
                </div>
                <div className='col-5 d-flex' >
                  <h6 className='  fs-4  fontmaaray' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}> كميه </h6>
                  <h6 className='  fs-4 fontmaaray ' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(total.totalqut).toFixed(2)})</h6>
                </div>
              </div>

              <div className='row '>
                <h6 className='col-1 fs-4 fontmaaray' style={{ 'color': props.headerObj.fontcolor }}> الوفر</h6>
                <hr className='mt-3 col-10' style={{ 'width': '60%', 'color': 'white' }}></hr>

                <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> {Number(total.totaldiscount).toFixed(2)}</h6>

              </div>
              <div className='row'>
                <h6 className='col-1 fs-4 fontmaaray' style={{  'color': props.headerObj.fontcolor }}> دليفري</h6>
                <hr className='mt-3 col-10' style={{ 'width': '60%', 'color': 'white' }}></hr>
{props.delvVal == undefined?
<h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:
<h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.delvVal).toFixed(2)}</h6>



}

              </div>
              <div className='row'>
                <h6 className='col-2 fs-4 fontmaaray' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
                <hr className='mt-3 col-7' style={{ 'color': 'white', 'width': '51.5%' }}></hr>
{props.delvVal == undefined? 
       <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal+props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:         
       <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.delvVal).toFixed(2)}</h6>
}

              </div>
            </div>




          </div>
          </div>
</>

          <div className='row justify-content-center btnprientdesktop'>
<div className='col-lg-3 col-12'>
<button className='btn  w-100 text-white fontmaaray' style={{background:"#FFC000",height:"54px",fontSize:"27px"}} onClick={createScreenshotPDF}>طباعه</button>
</div>
</div>
<div className='row justify-content-center btnprientmobile'>
<div className='col-lg-3 col-12'>
<button className='btn  w-100 text-white fontmaaray' style={{background:"#FFC000",height:"54px",fontSize:"27px"}} onClick={createScreenshotPDFMob}>طباعه</button>
</div>
</div>
{/* <div className='row justify-content-center btnprientmobile'>
<div className='col-lg-3 col-12'>
<button className='btn  w-100 text-white fontmaaray' style={{background:"#FFC000",height:"54px",fontSize:"27px"}} onClick={createScreenshotPDFMob}>طباعه</button>
</div>
</div> */}
      </div>
      <div className='container-fluid'>
        <div className='row justify-content-center ' id='orderprint2'>
          <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
            <div className='container-fluid bord'>

              <div className='row bg-primary justify-content-center mt-1 roundedtitle'>
                <h1 className='col-1'>  <BsCart2 className=' text-white fs-1 mt-2' />  </h1>
                <h1 className='text-light col-10 text-center fontmaaray mt-1'>اوردر</h1>
              </div>
              {/*table of order item */}
              <div className='row fontmaaray justify-content-center mt-3' id='myTable'>

                <h6 className='col-1 fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>كميه</h6>
                <h6 className='col-4  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>صنف</h6>
                <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>خصم</h6>
                <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>سعر</h6>
                <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>إجمالي</h6>

                {/*maping from list to show order details [name, quntity,total discount,total ] */}
                {props.OrderItemLst.map(order => (


                  <div className='row justify-content-center fontmaaray position-relative  my-2' >
                    <button className='btn btn-danger d-block d-md-none py-0 position-absolute' onClick={() => showpopupprice(order)} style={{ zIndex: "5", left: "5px", top: "-5px", width: "auto" }}><EditIcon sx={{ fontSize: "1rem" }}></EditIcon></button>
                    <h6 className='col-1 fontmaaray ' style={{ 'color': props.headerObj.fontcolor,direction:"ltr" }}>{order.qty}</h6>
                    <h6 className='col-11 fontmaaray pe-4  ' style={{ 'color': props.headerObj.fontcolor, textAlign: "right" }}>{order.itemName}</h6>
                    <div className='col-5'></div>
                    <h6 className='col-2 fontmaaray  ' style={{ 'color': props.headerObj.fontcolor }}>{(Number(order.discount).toFixed(2).toLocaleString())}  </h6>
                    <h6 className='col-3 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{(Number(order.netprice).toFixed(2).toLocaleString())}</h6>
                    <h6 className='col-2 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }} >{(order.total.toLocaleString())}</h6>
                    <hr className='mt-3 col-9' style={{ 'margin-right': '6%' }}></hr>

                  </div>


                ))}
             

                {/*total of order  */}


              </div>

            </div>
            <div className='container-fluid'>

              <div className='row'>
                <div className='col-7 d-flex '>
                  <h6 className=' fs-4 fontmaaray ' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>  عدد الأصناف </h6>
                  <h4 className='   fs-4  fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(props.OrderItemLst.length).toFixed(2)})</h4>
                </div>
                <div className='col-5 d-flex' >
                  <h6 className='  fs-4  fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}> كميه </h6>
                  <h6 className='  fs-4 fontmaaray ' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(total.totalqut).toFixed(2)})</h6>
                </div>
              </div>

              <div className='row '>
                <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> الوفر</h6>
                <hr className='mt-3 col-10' style={{ 'width': '60%', 'color': 'white' }}></hr>

                <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> {Number(total.totaldiscount).toFixed(2)}</h6>

              </div>
              <div className='row'>
                <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> دليفري</h6>
                <hr className='mt-3 col-10' style={{ 'width': '60%', 'color': 'white' }}></hr>
{props.delvVal == undefined?
<h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:
<h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.delvVal).toFixed(2)}</h6>



}

              </div>
              <div className='row'>
                <h6 className='col-2 fs-4 fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
                <hr className='mt-3 col-7' style={{ 'color': 'white', 'width': '51.5%' }}></hr>
{props.delvVal == undefined? 
       <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal+props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:         
       <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.delvVal).toFixed(2)}</h6>
}

              </div>
            </div>




          </div></div>


      </div>
      <div></div>



    </div>
  );

};

export default BasicExample;
